import {
  GET_IGO_ISSUES_COUNT,
  IGetIGOIssuesCountRequest,
  IGetIGOIssuesCountResponse,
} from "@/queries/ReceivedApplications";
import { getUserRole, isLoggedIn } from "@/services/auth";
import { RoleGroup } from "@/types/RoleGroup";
import { useLazyQuery } from "@apollo/client";
import { useRouter } from "next/router";
import React, { ReactNode, useEffect, useState } from "react";
import { useContext } from "react";

interface IReceivedApplications {
  updateIGOIssuesCount: () => void;
  applicationsWithIGOIssueCount?: number;
}

export const ReceivedApplicationsContext =
  React.createContext<IReceivedApplications>({
    updateIGOIssuesCount: () => {},
    applicationsWithIGOIssueCount: undefined,
  });

const ReceivedApplicationsProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const router = useRouter();
  const [applicationsWithIGOIssueCount, setApplicationsWithIGOIssueCount] =
    useState<number | undefined>();

  const [getIGOIssuesCount] = useLazyQuery<
    IGetIGOIssuesCountResponse,
    IGetIGOIssuesCountRequest
  >(GET_IGO_ISSUES_COUNT, {
    fetchPolicy: "network-only",
  });

  const updateIGOIssuesCount = () => {
    if (!isLoggedIn() || getUserRole() !== RoleGroup.Admin) return;
    getIGOIssuesCount().then((result) =>
      setApplicationsWithIGOIssueCount(result.data?.getIGOIssuesCount)
    );
  };

  useEffect(() => {
    updateIGOIssuesCount();
  }, [router]);

  return (
    <ReceivedApplicationsContext.Provider
      value={{
        updateIGOIssuesCount,
        applicationsWithIGOIssueCount,
      }}
    >
      {children}
    </ReceivedApplicationsContext.Provider>
  );
};

export const useReceivedApplications = () =>
  useContext(ReceivedApplicationsContext);

export default ReceivedApplicationsProvider;
