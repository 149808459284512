// The format here is that the object keys traversal should match the url sequence.
// So, /admin/organizations/create should be located at path.admin.organizations.create.
// There are some exceptions for the last step, such as when it's a list view

import { ApplicationType } from "../types/ApplicationType";

// (i.e: admin.organizations.list), where the final part of the URL is not required to match.
export const paths = {
  root: {
    index: () => "/",
  },
  admin: {
    organizations: {
      list: () => "/admin/organizations",
      create: () => "/admin/organizations/create",
      details: (organizationId: string) =>
        `/admin/organizations/${organizationId}`,
      edit: (organizationId: string) =>
        `/admin/organizations/${organizationId}?editMode=true`,
    },
    brokers: {
      list: () => `/admin/brokers`,
      details: (brokerId: string) => `/admin/brokers/${brokerId}`,
    },
    groups: {
      list: () => `/admin/groups`,
      details: (groupId: string) => `/admin/groups/${groupId}`,
    },
    articles: {
      create: () => "/admin/articles/create",
      edit: (articleId: string) => `/admin/articles/${articleId}/edit`,
      list: () => "/admin/articles",
    },
    links: {
      create: () => "/admin/links/create",
    },
    applications: {
      index: () => "/admin/applications",
    },
  },
  brokers: {
    details: () => `/brokers/me`,
    articles: () => `/brokers/articles`,
    organization: (userId: string) => `/brokers/${userId}/organization`,
  },
  dashboard: ({
    queryParams,
  }: {
    queryParams?:
      | string
      | string[][]
      | Record<string, string>
      | URLSearchParams
      | undefined;
  } = {}) => {
    const queryString = queryParams
      ? new URLSearchParams(queryParams).toString()
      : "";

    return `/dashboard${queryString.length > 0 ? "?" : ""}${queryString}`;
  },
  signin: () => "/signin",
  verify: () => "/verify",
  verifyEmail: () => "/brokers/verifyEmail",
  articles: {
    view: (articleId: string) => `/articles/${articleId}/view`,
  },
  business: {
    index: () => "/my-business",
    applications: {
      create: () => "/my-business/applications/create",
      selectProspect: ({
        productId,
        groupId,
        applicationType,
      }: {
        groupId?: string;
        productId: string;
        applicationType: ApplicationType;
      }) =>
        `/my-business/applications/create/products/${productId}?applicationType=${applicationType}${
          groupId ? `&groupId=${groupId}` : ""
        }`,
      client: ({
        groupId,
        productId,
        prospectId,
        applicationType,
      }: {
        groupId?: string;
        productId: string;
        prospectId?: string;
        applicationType: ApplicationType;
      }) => {
        const baseUrl = `/my-business/applications/create/products/${productId}/client?applicationType=${applicationType}`;
        const queryParams: string[] = [];

        if (prospectId) {
          queryParams.push(`prospectId=${prospectId}`);
        }

        if (groupId) {
          queryParams.push(`groupId=${groupId}`);
        }

        return `${baseUrl}${
          queryParams.length > 0 ? `&${queryParams.join("&")}` : ""
        }`;
      },
      applicant: (
        productId: string,
        applicantEmail: string,
        applicationType: ApplicationType
      ) =>
        `/my-business/applications/create/products/${productId}/applicant/${encodeURIComponent(
          applicantEmail
        )}?applicationType=${applicationType}`,
      send: (
        productId: string,
        applicantEmail: string,
        applicationType: ApplicationType
      ) =>
        `/my-business/applications/create/products/${productId}/applicant/${encodeURIComponent(
          applicantEmail
        )}/send?applicationType=${applicationType}`,
      complete: (
        linkShortId: string,
        productId: string,
        applicantEmail: string,
        applicationType: ApplicationType,
        token?: string
      ) => {
        const baseUrl = `/my-business/applications/${linkShortId}/products/${productId}/applicant/${encodeURIComponent(
          applicantEmail
        )}/complete?applicationType=${applicationType}`;

        const queryParams: string[] = [];

        if (token) {
          queryParams.push(`token=${token}`);
        }

        return `${baseUrl}${
          queryParams.length > 0 ? `&${queryParams.join("&")}` : ""
        }`;
      },
      plans: (
        linkShortId: string,
        productId: string,
        applicantEmail: string,
        applicationType: ApplicationType,
        token?: string,
        planA?: string,
        planB?: string,
        planC?: string
      ) => {
        const baseUrl = `/my-business/applications/${linkShortId}/products/${productId}/applicant/${encodeURIComponent(
          applicantEmail
        )}/plans?applicationType=${applicationType}`;

        const queryParams: string[] = [];

        if (token) {
          queryParams.push(`token=${token}`);
        }

        if (planA) {
          queryParams.push(`planA=${planA}`);
        }

        if (planB) {
          queryParams.push(`planB=${planB}`);
        }

        if (planC) {
          queryParams.push(`planC=${planC}`);
        }

        return `${baseUrl}${
          queryParams.length > 0 ? `&${queryParams.join("&")}` : ""
        }`;
      },
      rateCalculator: (
        linkShortId: string,
        productId: string,
        applicantEmail: string,
        applicationType: ApplicationType,
        token?: string
      ) => {
        const baseUrl = `/my-business/applications/${linkShortId}/products/${productId}/applicant/${encodeURIComponent(
          applicantEmail
        )}/rate-calculator?applicationType=${applicationType}`;

        const queryParams: string[] = [];

        if (token) {
          queryParams.push(`token=${token}`);
        }

        return `${baseUrl}${
          queryParams.length > 0 ? `&${queryParams.join("&")}` : ""
        }`;
      },
      sendPlans: (
        linkShortId: string,
        productId: string,
        applicantEmail: string,
        applicationType: ApplicationType
      ) => {
        const baseUrl = `/my-business/applications/${linkShortId}/products/${productId}/applicant/${encodeURIComponent(
          applicantEmail
        )}/send-plans?applicationType=${applicationType}`;

        const queryParams: string[] = [];

        return `${baseUrl}${
          queryParams.length > 0 ? `&${queryParams.join("&")}` : ""
        }`;
      },
    },
    prospects: {
      index: () => "/my-business/prospects",
      view: (id: string) => `/my-business/prospects/${id}/view`,
      create: () => "/my-business/prospects/create",
      edit: (id: string) => `/my-business/prospects/${id}/edit`,
    },
    pendingCases: {
      index: () => "/my-business/pending-cases",
      view: (id: string) => `/my-business/pending-cases/${id}/view`,
    },
    documents: {
      index: () => `/my-business/documents`,
      details: (runnerId: string, signatureId: string) =>
        `/my-business/documents/${runnerId}/${signatureId}`,
    },
  },
  groups: {
    index: () => "/groups",
    myGroups: {
      index: () => "/groups/my-groups",
      details: (id: string) => `/groups/my-groups/${id}`,
    },
    groupApprovalRequests: {
      add: (id?: string) =>
        `/groups/group-approval-requests/add/${id ? id : ""}`,
    },
    groupQuotes: {
      index: () => "/groups/group-quotes",
      create: () => "/groups/group-quotes/create",
      details: (id: string) => `/groups/group-quotes/${id}`,
    },
    groupRateSheets: {
      create: () => "/groups/group-rate-sheets/create",
    },
  },
  materials: {
    index: () => "/materials",
    customer: {
      send: (state: string, productId: string) =>
        `/materials/customer/state/${state}/product/${productId}/document-send`,
      gallery: (state: string, productId: string) =>
        `/materials/customer/state/${state}/product/${productId}/document-gallery`,
    },

    group: {
      gallery: (productId: string) =>
        `/materials/group/product/${productId}/document-gallery`,
    },
    producer: {
      gallery: (productId: string) =>
        `/materials/producer/product/${productId}/document-gallery`,
    },

    application: {
      gallery: (productId: string) =>
        `/materials/application/product/${productId}/document-gallery`,
    },
  },
  individual: {
    index: () => "/individual",
    salesIllustrations: {
      index: () => "/individual/sales-illustrations",
      create: () => "/individual/sales-illustrations/create",
      view: (id: string) => `/individual/sales-illustrations/view/${id}`,
    },
    individualRateSheets: {
      create: () => "/individual/individual-rate-sheets/create",
    },
    individualRateCalculator: {
      calculate: () => "/individual/individual-rate/calculate",
    },
  },
  onboarding: {
    index: () => "/onboarding",
    welcome: () => "/onboarding/welcome",
    contactInformation: () => "/onboarding/contact-information",
    agentInformation: () => "/onboarding/agent-information",
    npnLookup: () => "/onboarding/npn-lookup",
    photo: () => "/onboarding/photo",
  },
};

export const ON_BOARDING_SIDEBAR_PATHS = [paths.onboarding.index()];

// The naming convention is:
// export type xyz = typeof paths.x.y.z;
export type MyBusinessIndex = typeof paths.business.index;
export type ProspectsIndex = typeof paths.business.prospects.index;
export type PendingCasesIndex = typeof paths.business.pendingCases.index;
export type AgentDocumentsIndex = typeof paths.business.documents.index;
export type ProducerMaterialsGallery = typeof paths.materials.producer.gallery;

export type MaterialsIndex = typeof paths.materials.index;
export type CustomerMaterialsGallery = typeof paths.materials.customer.gallery;
export type GroupMaterialsGallery = typeof paths.materials.group.gallery;
export type ApplicationMaterialsGallery =
  typeof paths.materials.application.gallery;

export type IndividualIndex = typeof paths.individual.index;
export type SalesIllustrationIndex =
  typeof paths.individual.salesIllustrations.index;
export type IndividualRateSheetsCreate =
  typeof paths.individual.individualRateSheets.create;
export type IndividualRateCalculator =
  typeof paths.individual.individualRateCalculator.calculate;

export type GroupsIndex = typeof paths.groups.index;
export type MyGroupsIndex = typeof paths.groups.myGroups.index;
export type GroupQuotesIndex = typeof paths.groups.groupQuotes.index;
export type GroupQuotesCreate = typeof paths.groups.groupQuotes.create;
export type GroupRateSheetsCreate = typeof paths.groups.groupRateSheets.create;

export type ArticlesView = typeof paths.articles.view;

export type BrokersDetails = typeof paths.brokers.details;
export type ProspectView = typeof paths.business.prospects.view;
