import { ClientData } from "@/components/Groups/Applications/Steps/ClientInformation";
import { IEAppProgress } from "@/types/Application";
import { MUTATION_RESULT_FIELDS } from "@/types/Fragments";
import { IPlanOption } from "@/types/PlanOption";
import { Process } from "@/types/Process";
import { gql } from "@apollo/client";
import { MutationResult } from "@reframe-financial/chaplin";
import { ApplicationType } from "../types/ApplicationType";
import {
  DateRange,
  IGOCheckAppointment,
  IGOCheckContinuingEducation,
  IGOCheckDetails,
  IGOCheckLicenseInformation,
  IGORuleVersion,
  ReceivedApplicationSortOption,
  ReceivedApplicationsPageResult,
  ReceivedApplicationStatus,
} from "@/types/ReceivedApplications";
import { SortDirection } from "@/constants/sortDirection";
import { State } from "@/constants/states";

export interface ISaveUserProfileDataRequest {
  input: {
    productId: string;
    organizationGroupId?: string;
    applicationType: ApplicationType;
    clientInformation: ClientData;
  };
}

export interface ISaveUserProfileDataResponse {
  saveUserProfileData: MutationResult;
}

export const SAVE_USER_PROFILE_DATA = gql`
  ${MUTATION_RESULT_FIELDS}
  mutation SaveUserProfileData($input: InputSaveUserProfileData!) {
    saveUserProfileData(input: $input) {
      ...MutationResultFields
    }
  }
`;

export interface ISendNewApplicationLinkRequest {
  input: {
    productId: string;
    email: string;
    recipientEmail?: string;
    phone?: string;
    showRateCalculator?: boolean;
    createPlanBundle?: boolean;
  };
}

export interface ISendNewApplicationLinkResponse {
  sendNewApplicationLink: MutationResult;
}

export const SEND_NEW_APPLICATION_LINK = gql`
  ${MUTATION_RESULT_FIELDS}
  mutation SendNewApplicationLink($input: InputSendNewApplicationLink!) {
    sendNewApplicationLink(input: $input) {
      ...MutationResultFields
    }
  }
`;

export interface ISendPlanSelectionLinkRequest {
  input: {
    productId: string;
    email: string;
    recipientEmail?: string;
    phone?: string;
  };
}

export interface ISendPlanSelectionLinkResponse {
  sendPlanSelectionLink: MutationResult;
}

export const SEND_PLAN_SELECTION_LINK = gql`
  ${MUTATION_RESULT_FIELDS}
  mutation SendPlanSelectionLink($input: InputSendPlanSelectionLink!) {
    sendPlanSelectionLink(input: $input) {
      ...MutationResultFields
    }
  }
`;

export interface IGetApplicantProgressByResponse {
  getApplicantProgressBy: IEAppProgress[] | null;
}

export interface IGetApplicantProgressByRequest {
  email: string;
  productId: string;
}

export const GET_APPLICANT_PROGRESS_BY = gql`
  query getApplicantProgressBy($email: String!, $productId: String!) {
    getApplicantProgressBy(email: $email, productId: $productId) {
      eAppId
      runnerId
      name
      description
      status
    }
  }
`;

export interface IGetReceivedApplicationsResponse {
  getReceivedApplications: ReceivedApplicationsPageResult;
}

export interface IGetReceivedApplicationsRequest {
  input: {
    productIds?: string[];
    organizationIds?: string[];
    brokerIds?: string[];
    groupIds?: string[];
    statuses?: ReceivedApplicationStatus[];
    dateRange?: DateRange;
    igoRulesVersions?: number[];
    sortBy: ReceivedApplicationSortOption;
    sortDirection: SortDirection;
    page: number;
    pageSize: number;
  };
}

export const GET_RECEIVED_APPLICATIONS = gql`
  query getReceivedApplications($input: InputGetReceivedApplications!) {
    getReceivedApplications(input: $input) {
      receivedApplications {
        email
        productId
        receivedDate
        productName
        organizationName
        brokerName
        applicantName
        groupName
        status
        igoRulesVersion
      }
      count
      metrics {
        received
        inForce
        pending
        declined
        igoIssue
        completionPercentage
      }
    }
  }
`;

export interface IGetIGOIssuesCountResponse {
  getIGOIssuesCount: number;
}

export interface IGetIGOIssuesCountRequest {}

export const GET_IGO_ISSUES_COUNT = gql`
  query getIGOIssuesCount {
    getIGOIssuesCount
  }
`;

export interface IGetIGOCheckResultResponse {
  getIGOCheckResult: {
    brokerName: string;
    issuedState: State;
    licenseInformation: IGOCheckLicenseInformation;
    details: IGOCheckDetails[];
    continuingEducation: IGOCheckContinuingEducation;
    appointments: IGOCheckAppointment;
    igoRulesVersions: IGORuleVersion[];
  };
}

export interface IGetIGOCheckResultRequest {
  input: {
    productId: string;
    email: string;
  };
}

export const GET_IGO_CHECK_RESULT = gql`
  query getIGOCheckResult($input: GetIGOCheckResultInput!) {
    getIGOCheckResult(input: $input) {
      brokerName
      issuedState
      licenseInformation {
        data {
          state
          licenseNumber
          licenseClass
          dateUpdated
          licenseExpire
        }
        error {
          type
          extraData
        }
      }
      details {
        data {
          lineOfAuthority
          loaCode
          authorityIssueDate
          status
        }
      }
      continuingEducation {
        data {
          npn
          qualificationState
          status
          reason
          qualificationDate
          throughDate
        }
        error {
          type
          extraData
        }
      }
      appointments {
        data {
          companyName
          fein
          lineOfAuthority
          status
          appointmentDate
        }
        error {
          type
          extraData
        }
      }
      igoRulesVersions {
        versionNumber
        created
      }
    }
  }
`;

export interface ICompletePreQualificationWithClientResponse {
  completePreQualificationWithClient: MutationResult;
}

export interface ICompletePreQualificationWithClientRequest {
  input: {
    productId: string;
    email: string;
  };
}

export const COMPLETE_PRE_QUALIFICATION_WITH_CLIENT = gql`
  ${MUTATION_RESULT_FIELDS}
  mutation CompletePreQualificationWithClient(
    $input: InputCompletePreQualificationWithClient!
  ) {
    completePreQualificationWithClient(input: $input) {
      ...MutationResultFields
    }
  }
`;

export interface ICreateApplicantTokenResponse {
  createApplicantToken: MutationResult;
}

export interface ICreateApplicantTokenRequest {
  input: {
    productId: string;
    email: string;
  };
}

export const CREATE_APPLICANT_TOKEN = gql`
  ${MUTATION_RESULT_FIELDS}
  mutation CreateApplicantToken($input: InputCreateApplicantToken!) {
    createApplicantToken(input: $input) {
      ...MutationResultFields
    }
  }
`;

export const GET_USER_PROFILE_PROCESS = gql`
  query GetApplicantProcess($processId: String!) {
    getApplicantProcess(processId: $processId) {
      completedAt
      contextData
      id
      operation
      responseData
      startedAt
      timedOut
    }
  }
`;

export interface IGetPlanBundleResponse {
  getPlanBundle: {
    id: string;
    email?: string;
    anonymousId?: string;
    productId: string;
    planOptions: IPlanOption[];
  } | null;
}

export interface IGetPlanBundleRequest {
  input: {
    email: string;
    productId: string;
  };
}

export const GET_PLAN_BUNDLE = gql`
  query getPlanBundle($input: InputGetPlanBundle!) {
    getPlanBundle(input: $input) {
      id
    }
  }
`;

export interface ICreatePlanBundleResponse {
  createPlanBundle: MutationResult;
}

export interface ICreatePlanBundleRequest {
  input: {
    email: string;
    productId: string;
  };
}

export const CREATE_PLAN_BUNDLE = gql`
  ${MUTATION_RESULT_FIELDS}
  mutation CreatePlanBundle($input: InputCreatePlanBundle!) {
    createPlanBundle(input: $input) {
      ...MutationResultFields
    }
  }
`;

export interface IGetPlanBundleProcessResponse {
  getPlanBundleProcess: Process;
}

export interface IGetPlanBundleProcessRequest {
  processId: string;
}

export const GET_PLAN_BUNDLE_PROCESS = gql`
  query GetPlanBundleProcess($processId: String!) {
    getPlanBundleProcess(processId: $processId) {
      completedAt
      contextData
      id
      operation
      responseData
      startedAt
      timedOut
    }
  }
`;

export interface IAgentSignatureData {
  applicantName: string;
  applicantEmail: string;
  productName: string;
  documentName: string;
  signatureId: string;
  runnerId: string;
}

export interface IGetAgentSignaturesResponse {
  getAgentPendingDocuments: IAgentSignatureData[];
}

export const GET_AGENT_PENDING_DOCUMENTS = gql`
  query getAgentPendingDocuments {
    getAgentPendingDocuments {
      applicantName
      applicantEmail
      productName
      documentName
      runnerId
      signatureId
    }
  }
`;

export interface IGetAgentSignaturesResponse {
  getAgentSignedDocuments: IAgentSignatureData[];
}

export const GET_AGENT_SIGNED_DOCUMENTS = gql`
  query getAgentSignedDocuments {
    getAgentSignedDocuments {
      applicantName
      applicantEmail
      productName
      documentName
      runnerId
      signatureId
    }
  }
`;

export interface IRetryIgoCheckResponse {
  retryIGOCheck: MutationResult;
}

export interface IRetryIgoCheckRequest {
  input: {
    productId: string
    email: string
  }
}

export const RETRY_IGO_CHECK = gql`
  ${MUTATION_RESULT_FIELDS}
  mutation retryIGOCheck($input: InputRetryIGOCheck!) {
    retryIGOCheck(input: $input) {
      ...MutationResultFields
    }
  }
`;
