import { ReactElement, ReactNode, useEffect, useState } from "react";
import type { NextPage } from "next";
import { ApolloProvider } from "@apollo/client/react";
import { HelloSignProvider } from "../context/HelloSignContext";

import { AppProps } from "next/app";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../styles/global.scss";
import "../dist/output.scss";
import { SideBarWidthProvider } from "../context/SideBarWidthProvider";

import RouteGuard from "../components/shared/RouteGuard";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import UserContextProvider from "@/context/UserContext";
import Head from "next/head";
import RouteHistoryContextProvider from "../context/RouteHistoryContext";
import { PermissionContextProvider } from "@/context/PermissionContext";
import apolloClient from "../apolloClient";
import hellosignEmbedded from "hellosign-embedded";
import { TakingLongerContextProvider } from "@reframe-financial/chaplin";
import OnBoardingStepProvider from "../context/OnBoardingStepContext";
import ReceivedApplicationsProvider from "../context/ReceivedApplicationsContext";
import OnBoardingGuard from "@/components/shared/OnBoardingGuard";

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const [sideBarWidth, setSideBarWidth] = useState<number>(0);
  const sideBarWidthValue = { sideBarWidth, setSideBarWidth };

  const [hellosignClient, setHellosignClient] =
    useState<hellosignEmbedded | null>(null);

  useEffect(() => {
    const HelloSign = require("hellosign-embedded");
    setHellosignClient(new HelloSign());
  }, []);

  const getLayout = Component.getLayout ?? ((page) => page);
  return (
    <>
      <Head>
        <title>Reframe Financial</title>
      </Head>
      <ApolloProvider client={apolloClient}>
        <OnBoardingStepProvider>
          <TakingLongerContextProvider>
            <PermissionContextProvider>
              <UserContextProvider>
                <RouteHistoryContextProvider>
                  <DndProvider backend={HTML5Backend}>
                    <HelloSignProvider value={hellosignClient}>
                      <SideBarWidthProvider value={sideBarWidthValue}>
                        <ReceivedApplicationsProvider>
                          <OnBoardingGuard>
                            {getLayout(
                              <RouteGuard>
                                <Component {...pageProps} />
                              </RouteGuard>
                            )}
                          </OnBoardingGuard>
                        </ReceivedApplicationsProvider>
                      </SideBarWidthProvider>
                    </HelloSignProvider>
                  </DndProvider>
                </RouteHistoryContextProvider>
              </UserContextProvider>
            </PermissionContextProvider>
          </TakingLongerContextProvider>
        </OnBoardingStepProvider>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover={false}
        />
      </ApolloProvider>
    </>
  );
}

export default MyApp;
